.device-status-card{
    border-right: 1px solid var(--#{$prefix}border-color);
    &:last-child {
        border: 0px;
    }
    @media (min-width: 768px) and (max-width: 1399.98px) { 
        &:nth-child(3) {
            border-right: 0px;
        }
        &:last-child { 
            border-right: 1px solid var(--#{$prefix}border-color);
        }
     }
    @media (max-width: 767.98px) {
        border-right: 0px;
        border-bottom: 1px solid var(--#{$prefix}border-color);
    }
}