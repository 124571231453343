.rules-wrapper {
  position: relative;
  overflow-x: hidden;
}

// left sidebar

.rules-leftsidebar {
  height: calc(100vh - #{$header-height} - #{$footer-height} - 8px);
  position: relative;
  background-color: var(--#{$prefix}secondary-bg);
  overflow-y: auto;
  width: 300px;
  transition: width 0.3s ease;
}

.rules-leftsidebar.collapsed {
  width: 0px;
}

.collapse-button {
  position: absolute;
  top: 50%;
  
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  background-color: #3577f1;
  height: 100px;
  padding: 5px 6px;
  z-index: 10;
  opacity: 0.8;
  transition: left 0.3s ease;
  &.open {
    left: 302px;
  }
  &.close {
    left: 0px;
  }
}

[data-bs-theme="dark"] {
  .collapse-button {
    background-image: linear-gradient(to right, #212529, #262a2f);
    opacity: 1;
  }
}

.rules-editarea {
  position: relative;
  background-color: var(--#{$prefix}body-bg);
  width: 100%;
}

.rule-node-item {
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rule-node {
  padding: 15px 15px 15px 50px;
  position: relative;
  border-radius: 10px;
  width: 250px;
  font-size: 14px;
  color: #222;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: #1a192b;
  background-color: $white;
}

.rule-node-icon{
  align-items: center;
  display: flex;
  font-size: 26px;
  justify-content: center;
  position: absolute;
  bottom: -1px;
  left: 5px;
  top: -1px;
  width: 40px;
  margin-right: 1rem;
}

.message-input-icon{
    align-items: center;
    display: flex;
    font-size: 26px;
    justify-content: center;
    position: absolute;
    bottom: -1px;
    right: 20px;
    top: -1px;
    width: 30px;
    margin-right: 1rem;
}

.rule-node-indigo {
  color: $white;
  background-color: $indigo;
  background-image: linear-gradient(180deg,#ffffff26,#fff0);;
}

.rude-node-purple {
  color: $white;
  background-color: $purple;
  background-image: linear-gradient(180deg,#ffffff26,#fff0);;
}

.rule-node-green {
  color: $white;
  background-color: $green;
  background-image: linear-gradient(180deg,#ffffff26,#fff0);;
}

.rule-node-red {
  color: $white;
  background-color: $red;
  background-image: linear-gradient(180deg,#ffffff26,#fff0);;
}

.rule-node-pink {
  color: $white;
  background-color: $pink;
  background-image: linear-gradient(180deg,#ffffff26,#fff0);;
}

.rule-node-blue {
  color: $white;
  background-color: $blue;
  background-image: linear-gradient(180deg,#ffffff26,#fff0);;
}

.rule-node-light {
  color: $dark;
  background-color: $light;
  background-image: linear-gradient(180deg,#ffffff26,#fff0);;
}

.rule-node-dark {
  color: $white;
  background-color: $dark;
  background-image: linear-gradient(180deg,#ffffff26,#fff0);;
}

.rule-node-orange {
  color: $white;
  background-color: $orange;
  background-image: linear-gradient(180deg,#ffffff26,#fff0);;
}

.rule-node-yellow {
  color: $dark;
  background-color: $yellow;
  background-image: linear-gradient(180deg,#ffffff26,#fff0);;
}

.rule-node-teal {
  color: $white;
  background-color: $teal;
  background-image: linear-gradient(180deg,#ffffff26,#fff0);;
}

.rule-node-cyan {
  color: $dark;
  background-color: $cyan;
  background-image: linear-gradient(180deg,#ffffff26,#fff0);;
}

.rule-save-toolbar{
  .cancel-btn{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding: 0;
    i{
      font-size: 25px;
    }
    &.disabled{
      background-color: $gray-400;
      border-color: $gray-400;
    }
  }
  .save-btn{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding: 0;
    i{
      font-size: 25px;
    }
    &.disabled{
      background-color: $gray-400;
      border-color: $gray-400;
    }
  }

}


// React flow node

.react-flow__node {
  border: 2px solid transparent;
  border-radius: 10px;
}

.react-flow__node.selected {
  border: 2px dashed $gray-700;
  border-radius: 10px;
}

