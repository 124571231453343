/* =================================== */
/*  6. Extras
/* =================================== */

/* 6.1 Form */

.auth-wrapper {

.form-control {
  border-color: #dae1e3;
  box-shadow: inset 0 0;
}

.form-check-input:not(:checked){border-color: #dae1e3;}


.form-control.bg-light{
	background-color:#f5f5f6!important;
}
.form-control.border-light{
	border-color:#f5f5f6!important;
}

.form-control:not(.form-control-sm) {
  padding: .810rem .96rem;
  height:inherit;
}

.form-control-sm{font-size:14px;}

.icon-group {
  position: relative;
  .form-control {
    padding-left: 44px;
  }
  .icon-inside {
    position: absolute;
    width: 50px;
    height: 54px;
    left: 0;
    top: 0;
    pointer-events: none;
    font-size: 18px;
    font-size: 1.125rem;
    color: #c4c3c3;
    z-index: 3;
    display: flex;
	-ms-flex-align: center !important;
	align-items: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
  }
  &.icon-group-end {
    .form-control {
      padding-right: 44px;
      padding-left: 0.96rem;
    }
    .icon-inside {
      left: auto;
      right: 0;
    }
  }
}

.form-control-sm + .icon-inside {
  font-size: 0.875rem !important;
  font-size: 14px;
  top: calc(50% - 13px);
}

select.form-control:not([size]):not([multiple]):not(.form-control-sm) {
  height: auto;
  padding-top: .700rem;
  padding-bottom: .700rem;
}


.form-control:focus{
  @include box-shadow(0 0 5px rgba(128, 189, 255, 0.5));
  border-color: #80bdff!important;
}
.form-control:focus[readonly]{box-shadow:none;}

.input-group-text {
  border-color: #dae1e3;
  background-color:#f1f5f6;
  color: #656565;
}


.form-control {
  &::-webkit-input-placeholder {
    color: #b1b4b6;
  }
  &:-moz-placeholder {
    /* FF 4-18 */
    color: #b1b4b6;
  }
  &::-moz-placeholder {
    /* FF 19+ */
    color: #b1b4b6;
  }
  &:-ms-input-placeholder, &::-ms-input-placeholder {
    /* IE 10+ */
    color: #b1b4b6;
  }
}

}

/* 6.2 Form Dark */

.auth-wrapper {
.form-dark {
  .form-control {
	border-color: #232a31;
	background:#232a31;
	color: #fff;
  }
  .form-control:focus{border-color: #80bdff!important;}
  .form-control {
	&::-webkit-input-placeholder {
	  color: #777b7f;
	}
	&:-moz-placeholder {
	  /* FF 4-18 */
	  color: #777b7f;
	}
	&::-moz-placeholder {
	  /* FF 19+ */
	  color: #777b7f;
	}
	&:-ms-input-placeholder, &::-ms-input-placeholder {
	  /* IE 10+ */
	  color: #777b7f;
	}
  }
  .icon-group {
	.icon-inside {
		color: #777b7f;
	}
  }
  .form-check-input:not(:checked){
	background-color:#232a31;
	border-color: #232a31;
  }
  .invalid-feedback {
    color: #f06548;
  }
}
}

/* 6.3 Form Border (Input with only bottom border)  */

.auth-wrapper {
.form-border {
  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0px;
    padding-left: 0px!important;
    color: rgba(0, 0, 0, 1);
	&::-webkit-input-placeholder {
	  color: rgba(0, 0, 0, 0.4);
	}
	&:-moz-placeholder {
	  /* FF 4-18 */
	  color: rgba(0, 0, 0, 0.4);
	}
	&::-moz-placeholder {
	  /* FF 19+ */
	  color: rgba(0, 0, 0, 0.4);
	}
	&:-ms-input-placeholder, &::-ms-input-placeholder {
	  /* IE 10+ */
	  color: rgba(0, 0, 0, 0.4);
	}
  }
  .form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
	border-bottom-color: rgba(0, 0, 0, 0.7);
	&.border-dark{
		border-color: var(--#{$prefix}themecolor)!important;
	}
  }
  
  .form-control:not(output):-moz-ui-invalid{
    &:not(:focus), &:-moz-focusring:not(:focus) {
      border-bottom-color: #e10203;
      box-shadow: none;
      -webkit-box-shadow: none;
	  &.border-dark{
		border-color: #e10203!important;
	}
    }
  }
  select option {
    color: #666;
  }
  
  .icon-group {
  .form-control {
    padding-left: 30px!important;
  }
  .icon-inside {
    color: rgba(0, 0, 0, 0.25);
	width: 30px;
    height: 52px;
    display: flex;
	-ms-flex-align: center !important;
	align-items: center !important;
	-ms-flex-pack: flex-start !important;
	justify-content: flex-start !important;
  }
  &.icon-group-end {
    .form-control {
      padding-right: 30px!important;
      padding-left: 0!important;
    }
    .icon-inside {
      left: auto;
      right: 0;
	  -ms-flex-pack: end !important;
	  justify-content: end !important;
    }
  }
}
  
}
}

/* 6.4 Form Border Light (Input with only bottom border)  */

.auth-wrapper {
.form-border-light {
  .form-control {
	border-bottom: 1px solid rgba(250, 250, 250, 0.3);
	color: rgba(250, 250, 250, 1);
	&::-webkit-input-placeholder {
	  color: rgba(250, 250, 250, 0.7);
	}
	&:-moz-placeholder {
	  /* FF 4-18 */
	  color: rgba(250, 250, 250, 0.7);
	}
	&::-moz-placeholder {
	  /* FF 19+ */
	  color: rgba(250, 250, 250, 0.7);
	}
	&:-ms-input-placeholder, &::-ms-input-placeholder {
	  /* IE 10+ */
	  color: rgba(250, 250, 250, 0.7);
	}
  }
  .form-control:focus{
	border-bottom-color: rgba(250, 250, 250, 0.8);
	&.border-light{
		border-color: var(--#{$prefix}themecolor)!important;
	}
  }
  .icon-group {
	.icon-inside {
		color: #777b7f;
	}
  }
  select option {
    color: #333;
  }
}
}

/* 6.5 Vertical Multilple input group */

.auth-wrapper {
.vertical-input-group .input-group {
  &:first-child {
    padding-bottom: 0;
    * {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &:last-child {
    padding-top: 0;
    * {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &:not(:last-child):not(:first-child) {
    padding-top: 0;
    padding-bottom: 0;
    * {
      border-radius: 0;
    }
  }
  &:not(:first-child) * {
    border-top: 0;
  }
}
}


/* 6.6 Other Bootstrap Specific */

.auth-wrapper {
.btn {
  padding:0.8rem 2.6rem;
  font-weight:500;
  @include transition(all .5s ease);
}

.btn-sm {
  padding: 0.5rem 1rem;
}

.btn:not(.btn-link) {
  @include box-shadow(0px 5px 15px rgba(0, 0, 0, 0.15));
  &:hover {
	@include box-shadow(0px 5px 15px rgba(0, 0, 0, 0.3));
	@include transition(all .5s ease);
  }
}

.oauth-btn {
  cursor: pointer;
}

.input-group-append .btn, .input-group-prepend .btn {
  @include box-shadow(none);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.input-group-append .btn:hover, .input-group-prepend .btn:hover {
  @include box-shadow(none);
}

@include media-breakpoint-only(xs) {
  .btn:not(.btn-sm){
    padding: .810rem 2rem;
  }
  .input-group > {
  .input-group-append > .btn, .input-group-prepend > .btn {
    padding: 0 0.75rem;
  }
}
}

.btn-primary{
    --#{$prefix}btn-color: var(--#{$prefix}white);
	--#{$prefix}btn-bg: var(--#{$prefix}themecolor);
	--#{$prefix}btn-border-color: var(--#{$prefix}themecolor);
	
    --#{$prefix}btn-hover-color: var(--bs-white);
	--#{$prefix}btn-hover-bg: var(--#{$prefix}themehovercolor);
	--#{$prefix}btn-hover-border-color: var(--#{$prefix}themehovercolor);
	
	--#{$prefix}btn-active-color: var(--bs-btn-hover-color);
	--#{$prefix}btn-active-bg: var(--#{$prefix}themehovercolor);
	--#{$prefix}btn-active-border-color: var(--#{$prefix}themehovercolor);
}

.btn-outline-primary{
    --#{$prefix}btn-color: var(--#{$prefix}themecolor);
	--#{$prefix}btn-border-color: var(--#{$prefix}themecolor);
	--#{$prefix}btn-hover-bg: var(--#{$prefix}themecolor);
	--#{$prefix}btn-hover-color: var(--bs-white);
	--#{$prefix}btn-hover-border-color: var(--#{$prefix}themecolor);
	--#{$prefix}btn-active-color: var(--bs-btn-hover-color);
	--#{$prefix}btn-active-bg: var(--#{$prefix}themehovercolor);
	--#{$prefix}btn-active-border-color: var(--#{$prefix}themehovercolor);
}

.progress, .progress-stacked{
	--#{$prefix}progress-bar-bg: var(--#{$prefix}themecolor);
}

.pagination {
	--#{$prefix}pagination-active-bg:var(--#{$prefix}themecolor);
	--#{$prefix}pagination-active-border-color: var(--#{$prefix}themecolor);	
}

}

/* styles-switcher */
#styles-switcher {
  background: #fff;
  width: 202px;
  position: fixed;
  top: 35%;
  z-index: 1051;
  padding: 20px;
  left: -202px;
  ul {
    padding: 0;
    li {
      list-style-type: none;
      width: 24px;
      height: 24px;
	  line-height:24px;
      margin: 4px 2px;
      border-radius: 50%;
      display: inline-block;
      cursor: pointer;
	  text-align:center;
	  color:#fff;
      transition: all .2s ease-in-out;
      &.blue {
        background: #007bff;
      }
      &.brown {
        background: #795548;
      }
      &.purple {
        background: #6f42c1;
      }
      &.indigo {
        background: #6610f2;
      }
      &.red {
        background: #dc3545;
      }
      &.orange {
        background: #fd7e14;
      }
      &.yellow {
        background: #ffc107;
      }
      &.green {
        background: #28a745;
      }
	  &.teal {
        background: #20c997;
      }
      &.pink {
        background: #e83e8c;
      }
      &.cyan {
        background: #17a2b8;
      }
      &.active {
        transform: scale(0.7);
		cursor:default;
      }
    }
  }
  .switcher-toggle {
    position: absolute;
    background: #555;
    color: #fff;
    font-size: 1.25rem;
    border-radius: 0px 4px 4px 0;
    right: -40px;
    top: 0;
    width: 40px;
    height: 40px;
    padding: 0;
	&:focus{
		box-shadow:none;
	}
  }
  #reset-color{background: #007bff;}
}

input:-internal-autofill-selected {
    background-color: transparent;
}

#styles-switcher.right{left:auto; right: -202px;}
#styles-switcher.right .switcher-toggle{right: auto; left: -40px; border-radius: 4px 0px 0px 4px;}

#password-validation {
  display: none;

  p {
      padding-left: 13px;
      font-size: .875rem;

      &.valid {
          color: $success;
          &::before {
            color: $success;
            position: relative;
            left: -8px;
            content: "✔";
          }
      }

      &.invalid {
          color: $danger;
          &::before {
            color: $danger;
            position: relative;
            left: -8px;
            content: "✖";
          }
      }
  }
}