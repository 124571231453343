/* =================================== */
/*  1. Basic Style 
/* =================================== */

body, html {
height:100%;
}

form {
  padding: 0;
  margin: 0;
  display: inline;
}

img {
  vertical-align: inherit;
}

p {
  line-height: 1.8;
}

iframe {
  border: 0 !important;
}