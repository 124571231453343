.top-toolbar {
  padding: 15px $grid-gutter-width;
  background-color: var(--#{$prefix}secondary-bg);
  box-shadow: var(--#{$prefix}page-title-box-shadow);
  border-bottom: 1px solid var(--#{$prefix}page-title-border);
  margin: -23px -0.72rem $grid-gutter-width -0.72rem;

  h4 {
    font-weight: 700;
    font-size: 18px !important;
  }
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px - #{$header-height} - #{$footer-height});
}

.widget-card {
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    box-shadow: $box-shadow;
    transform: translateY(-5px);
  }
}

.widgets-area {
  height: calc(100vh - 200px - #{$header-height} - #{$footer-height});
}

[data-bs-theme="dark"] {
  .react-resizable-handle {
    filter: invert(100%);
  }
}
