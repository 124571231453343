
.documentItem{
    cursor: pointer;
    margin-bottom: 0;
    background-color: tint-color(#3577f1, 85%);
    border-radius: .25rem;
    padding: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    position: relative;
    padding-right: 32px;

    &:hover {
        cursor: pointer;
    }
}