@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/variables-dark";
@import "./node_modules/bootstrap/scss/mixins";
@import "./node_modules/bootstrap/scss/vendor/rfs";

$variable-prefix:             lc-; 
$prefix:                      $variable-prefix;


//-------------------- Base Colors --------------------//

$theme-color: 			$blue;

$theme-hover-color :    darken($theme-color, 10%);

$body-color:			#4c4d4d;

//------------- Fonts -------------//

$body-font-family: 'Poppins', sans-serif;

//-------------------- Social Colors --------------------//

$facebook:                 #3b5998;
$twitter:                  #1da1f2;
$google:                   #4285f4;
$linkedin:                 #0077b5;
$instagram:                #e1306c;
$vk:                       #4a76a8;
$yahoo:                    #410093;
$apple:                    #ffffff;
$windows:                  #00adef;
$amazon:                   #ff9900;
$github:                   #ffffff;

$success:                  #45CB85;
$danger:                   #f06548;