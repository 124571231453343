@media (max-width: 768px) {
  .offcanvas.offcanvas-end {
    width: 75vw;
  }
}

@media (max-width: 576px) {
  .offcanvas.offcanvas-end {
    width: 90vw;
  }
}
