//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "../components/utilities";
@import "./node_modules/bootstrap/scss/bootstrap";

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.mt-8 {
  margin-top: 6rem !important;
}

.mt-9 {
  margin-top: 7rem !important;
}

.mt-10 {
  margin-top: 8rem !important;
}

.top-10 {
  top: 10px !important;
}

.top-20 {
  top: 20px !important;
}

.top-30 {
  top: 30px !important;
}

.top-40 {
  top: 40px !important;
}

.top-50 {
  top: 50px !important;
}

.top-60 {
  top: 60px !important;
}

.top-70 {
  top: 70px !important;
}

.text-justify {
  text-align: justify;
}

.hide-text-sm {
  display: inline-block;
}

@media (max-width: 768px) {
  .hide-text-sm {
    display: none;
  }
}


