
.auth-wrapper {
    :is(.h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6) {
        font-family: $body-font-family;
    }

    h3{
        font-size: 1.75rem;
    }

    label{
        font-family: $body-font-family;
        font-size: 1rem;
    }

    a{
        color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity, 1));
        text-decoration: underline;
    }

    p{
        font-size: 1rem;
        font-family: $body-font-family;
    }

    .btn{
        font-family: $body-font-family;
        font-size: 1rem;
        line-height: 1.5;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
    }

    .form-control {
        font-family: $body-font-family;
        font-size: 1rem;
        line-height: 1.5;
    }
}