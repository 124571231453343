//Fonts
@import "./fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./config/variables";
@import "./config/variables-custom";
@import "./config/variables-dark";
@import "./node_modules/bootstrap/scss/mixins";
@import "./config/bootstrap.scss";
@import "./config/custom.scss";
@import "../common/icons.scss";
@import "./plugins/shepherd.css";
@import "./plugins/mermaid.min.css";

// Structure
@import "./structure/topbar";
@import "./structure/page-head";
@import "./structure/footer";
@import "./structure/vertical";

// Components
@import "./components/waves";
@import "./components/avatar";
@import "./components/accordion";
@import "./components/helper";
@import "./components/preloader";
@import "./components/forms";
@import "./components/widgets";
@import "./components/demos";
@import "./components/print";
@import "./components/ribbons";
@import "./components/toast";
@import "./components/scrollspy";
@import "./components/intro-panel";

// Bootstrap components
@import "./components/root";
@import "./components/reboot";
@import "./components/alerts";
@import "./components/badge";
@import "./components/buttons";
@import "./components/breadcrumb";
@import "./components/card";
@import "./components/dropdown";
@import "./components/nav";
@import "./components/table";
@import "./components/modal";
@import "./components/pagination";
@import "./components/progress";
@import "./components/popover";
@import "./components/type";
@import "./components/form-check";
@import "./components/form-control";
@import "./components/list-group";

// Plugins
@import "./plugins/custom-scrollbar";
@import "./plugins/prismjs";
@import "./plugins/sweetalert2";
@import "./plugins/dropzone";
@import "./plugins/range-slider";
@import "./plugins/sortablejs";
@import "./plugins/tour";
@import "./plugins/swiper";
@import "./plugins/multijs";
@import "./plugins/colorpicker";
@import "./plugins/filepond";
@import "./plugins/form-input-spin";
@import "./plugins/ckeditor";
@import "./plugins/quilljs";
@import "./plugins/gridjs";
@import "./plugins/listjs";
@import "./plugins/apexcharts";
@import "./plugins/chartjs";
@import "./plugins/echarts";
@import "./plugins/google-map";
@import "./plugins/autocomplete";
@import "./plugins/vector-maps";
@import "./plugins/leaflet-maps";
@import "./plugins/fullcalendar";
@import "./plugins/emoji-picker";
@import "./plugins/datatables";
@import "./plugins/select2";
@import "./plugins/react-clock";
@import "./plugins/offcanvas";
// Plugins
@import "./plugins/toastify";
@import "./plugins/choices";
@import "./plugins/flatpicker";
@import "./plugins/flag-input";

// Pages
@import "./pages/accounts-settings";
@import "./pages/dashboard-detail";
@import "./pages/rule-engine.scss";
@import "./pages/device-detail.scss";


@import "./config/themes.scss";