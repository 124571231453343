/* =================================== */
/*  3. Layouts
/* =================================== */

.auth-wrapper {
	.section {
	  position: relative;
	  padding: 4.5rem 0;
	  overflow:hidden;
	}

	@include media-breakpoint-down(sm) {
		.section {
			padding: 3.5rem 0;
		}
	}

	@include media-breakpoint-down(sm) {
		.modal .close{
			position:absolute;
			z-index:1;
			right:8px;
		}
	}
}